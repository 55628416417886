import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Typography } from 'antd';
import Menu from '../../component/Menu/Menu';
import Footer from '../../component/Footer/Footer';
import { Div } from '../../framework';
import { setFlyoutOpen, setMenuMode } from '../../store/app.slice';
import * as analyticService from '../../service/analytic.service';

import './Privacy.scss';

const { Title, Paragraph, Link, Text } = Typography;

const companyName = 'Heytech Sdn Bhd';
const privacyUrl = 'https://heycast.me/site-policy/privacy';
const email = 'hello@heycast.me';

const companyComp = <Text strong>{companyName}</Text>;
const privacyLink = <Link href={privacyUrl}>{privacyUrl}</Link>;
const emailLink = <Link href={`mailto://${email}`}>{email}</Link>;

const PrivacyPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    analyticService.trackPage('privacy');
  }, []);

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  return (
    <Div className='page-privacy'>
      <Menu />
      <Helmet>
        <title>Privacy Statement - HeyCast.Me</title>
        <meta
          name='description'
          content='The right role. The right talent. The right platform.'
        />
      </Helmet>
      <section className='statement'>
        <Title level={2}>Privacy Statement</Title>
        <Title level={3}>Privacy Policy</Title>

        <Paragraph>
          {companyComp} recognizes the importance of protecting your privacy. We
          are committed to safeguarding the privacy of all {companyComp} users.
          This privacy policy explains how we may collect, hold, use and
          disclose information that identifies you. It applies to all personal
          information collected, stored, used and transmitted by {companyComp}{' '}
          (owned and operated by {companyComp}). {companyComp}&apos;s contact
          information is provided at the end of this privacy policy.
        </Paragraph>

        <Paragraph>
          We may amend this privacy policy at any time by posting the amended
          policy on our website (&quot;Website&quot;). All amendments to the
          privacy policy will take effect immediately upon such posting (or, if
          applicable, on the date specified in the updated privacy policy) and
          will apply to all personal information that we hold about you. If the
          changes are material, we may also take additional steps to bring the
          changes to your attention, as required by law, which may include
          notifying you directly.
        </Paragraph>

        <Paragraph>
          {companyComp}&apos;s services are described in full in {companyComp}
          &apos;s terms of use (&quot;Terms of Use&quot;), as updated from time
          to time
        </Paragraph>

        <Paragraph>
          Please read this privacy policy carefully. It applies to any personal
          information that you provide to {companyComp} or authorise us to
          collect. By providing {companyComp} with personal information, or
          using our Website or other services, you authorise us to collect, use
          and disclose your personal information in accordance with this privacy
          policy. This privacy policy applies in addition to, and does not
          limit, our rights and obligations under applicable privacy laws or any
          specific authorisation that you provide to us when you interact with
          us.
        </Paragraph>

        <Paragraph>
          This policy was updated, in response to the Personal Data Protection
          Act 2010 – referred to, throughout, as PDPA.
        </Paragraph>

        <Title level={3}>Privacy of your information</Title>

        <Paragraph>
          {companyComp} collects personal information about you through your use
          of the services and the Website, including:
        </Paragraph>

        <ul>
          <li>
            your registration details and profile information (including your
            name, date of birth, contact information, demographic information,
            location, photos, videos, social media information, work history and
            any other relevant information)
          </li>
          <li>
            information you submit when you contact us, apply to listings, or
            send messages to other {companyComp} users through the Website
          </li>
          <li>
            and other information relating to your use of the Website and the
            content you access (including your device, IP address and the pages
            you visit).
          </li>
        </ul>

        <Paragraph>
          {companyComp} may also receive personal information about you
          indirectly from another {companyComp} user who has included your
          details on their account profile (as a close contact).
        </Paragraph>

        <Paragraph>
          If {companyComp} is unable to collect personal information from you or
          you do not provide requested information, your use of {companyComp}
          &apos;s services may suffer from reduced functionality, and certain
          features of the service may be disabled or unavailable to you.
        </Paragraph>

        <Paragraph>
          You agree that {companyComp} may process your personal information:
        </Paragraph>

        <ul>
          <li>
            as necessary to provide the services to you (or the account holder
            who has provided {companyComp} with your personal information) and
            otherwise comply with its obligations to you under the Terms of Use.
            For example:{' '}
            <ul>
              <li>
                all personal information that you provide to {companyComp} for
                inclusion in your personal profile will be collected and stored
                on the {companyComp} Website to enable visitors to the Website
                and advertisers to view your profile, and your profile will also
                be accessible from search engines such as Google (unless you
                have set your profile to Private); and
              </li>
              <li>
                where you choose to apply for any role advertised through{' '}
                {companyComp} your contact details and any information entered
                into your personal profile will be provided to the advertiser
                (including any photo you have uploaded);
              </li>
              <li>
                where you have placed a listing or advertisement, any
                information entered into the listing details will be provided to
                applicants;
              </li>
            </ul>
          </li>
          <li>
            where it is necessary for the purposes of a legitimate interest
            pursued by {companyComp}, being for ensuring Trust and Safety of
            users of the service, or {companyComp}&apos;s internal business
            purposes (including in connection with any audits, professional
            advice, research, testing, analytics, fraud prevention, or process
            and service improvements), to verify your identity, and for
            contacting you from time to time about your registration, account
            updates, and promotional information about the {companyComp} Service
            and features;
          </li>
          <li>
            where necessary for compliance with a legal obligation to which{' '}
            {companyComp} is subject. For example, {companyComp} may disclose
            your personal information, including your name and contact details,
            to the relevant authorities, parties and/or the applicable
            intellectual property right holders (or their representatives) to
            comply with any notification and reporting obligations and any
            access directions imposed on us by Government agency, and otherwise
            where the law requires or allows us to do so;
          </li>
          <li>
            for promotional and direct marketing purposes, including by email
            and other electronic means (unless you have unsubscribed); or
          </li>
          <li>for any other purpose authorised by you or applicable law.</li>
        </ul>

        <Title level={3}>Your Rights</Title>

        <Paragraph>
          You have the right to request access to and correction of your
          personal information that is held by us at any time, or to make a
          complaint regarding your privacy. Requests for such access and
          correction requirements or complaints can be made to the contact
          details at the end of this privacy policy.
        </Paragraph>

        <Paragraph>
          Please note that where {companyComp} is not, or is no longer, in a
          position to identify you within the information we hold (including
          because of any de-identification techniques we may have employed),
          then your rights as described above shall not apply.
        </Paragraph>

        <Paragraph>
          {companyComp} will respond to any request made in respect of the above
          in accordance with the PDPA where you are resident of the Malaysia.
        </Paragraph>

        <Paragraph>
          {companyComp} will respond to any request made in respect of the above
          as soon as reasonably practicable, but in any case within 20 working
          days of a request, or an extended period where the requests are
          complex or numerous (in which case, we will inform you of such delay
          within 20 working days of the request).
        </Paragraph>

        <Title level={3}>Data Minimisation, Retention and Erasure</Title>

        <Paragraph>
          {companyComp} will only collect personal information that is necessary
          for the legitimate purposes and on the lawful basis described in this
          privacy policy, and otherwise as expressly consented to by you or
          permitted by law.
        </Paragraph>

        <Paragraph>
          If you make a request for {companyComp} to delete your personal
          information, and we no longer have a legitimate purpose to retain your
          data, we will securely delete your profile and personal data. Any
          back-up copies of your data will be archived out and deleted within 45
          days of your request being actioned.
        </Paragraph>

        <Paragraph>
          If you wish to retain a copy of your personal information (or any part
          of it) please contact {companyComp} (using the details given at the
          end of this privacy policy) prior to you requesting deletion of your
          data.
        </Paragraph>

        <Paragraph>Despite the above, {companyComp} may:</Paragraph>

        <ol type='a'>
          <li>
            in some cases, retain a copy of your profile and account activity in
            order to fulfil our Trust and Safety obligations. Data retained for
            Trust and Safety purposes will be archived and stored in a secure
            manner after your account has been closed, and will not be accessed
            unless required for Trust and Safety reasons.
          </li>
          <li>
            Retain personal information in an aggregated, de-identified or
            otherwise anonymous form, such that there is no reliable way of
            identifying you from the information.
          </li>
        </ol>

        <Paragraph>
          In some cases our third party service providers will retain your
          personal information in accordance with their data retention and
          erasure policies. In cases where you request that your personal
          information is deleted from {companyComp}, we will work with those
          third party service providers to remove your personal information from
          their systems as well, on a reasonable endeavours basis.
        </Paragraph>

        <Title level={3}>
          Personal information of third parties provided by you
        </Title>

        <Paragraph>
          You acknowledge and agree that, in respect of other person&apos;s
          personal information (including the personal information of your close
          contacts) that you provide us with, authorise us to collect or upload
          into (or link to) your profile and make available within the{' '}
          {companyComp} Website, App and/or services, you will:
        </Paragraph>

        <ul>
          <li>
            comply with your obligations under all applicable data protection
            and privacy laws;
          </li>
          <li>
            have obtained (or shall obtain) all consents necessary, for{' '}
            {companyComp} to lawfully process the personal information through
            the {companyComp} Website, App and services in the manner
            anticipated by this Privacy Policy and the Terms of Use (including
            making such personal information available to advertisers and talent
            agencies and professionals), and that such consent is obtained from
            the correct person;
          </li>
          <li>
            ensure that any person whose personal information you upload is
            aware of this Privacy Policy, including their rights to access and
            request correction of their personal information;
          </li>
          <li>
            notify us without undue delay if any person withdraws their consent,
            or any part of their consent, or objects to any processing of their
            personal information through the {companyComp} Website, App or
            services. This shall include any withdrawal of consent, or objection
            received by you from a person to whom the personal information
            relates;
          </li>
          <li>
            will make sure that you are frequently updating any personal
            information included in (or linked to) your profile that relates to
            those other persons when requested to do so by a that person;
          </li>
          <li>
            will not upload or transfer &quot;sensitive data&quot; or
            &quot;sensitive information&quot; (as those terms, or similar terms,
            are defined in any applicable data protection or privacy laws);
          </li>
          <li>
            acknowledge that the Services are subject to change and that{' '}
            {companyComp} may update or modify its Terms of Use and this Privacy
            Policy from time to time, and you agree to review the latest version
            of the Terms of Use and Privacy Policy from time to time.
          </li>
        </ul>

        <Title level={3}>Disclosure of personal information</Title>

        <Paragraph>
          We may disclose personal information for the purposes set out in this
          privacy policy, and to assist us with the operation of our business,
          with the following parties:
        </Paragraph>

        <ul>
          <li>our related companies and organisations and partners;</li>
          <li>
            third party providers, in accordance with the section headed
            &quot;Third Party Services&quot; below;
          </li>
          <li>
            authorities, regulators and professional advisers (including
            lawyers, bankers, auditors and insurers);
          </li>
          <li>
            third parties to whom we may choose to sell, transfer or merge all
            or parts of our business or assets; and
          </li>
          <li>
            any other party authorised by you or permitted in accordance with
            applicable law.
          </li>
        </ul>

        <Title level={3}>Data Security</Title>

        <Paragraph>
          {companyComp} will collect, store, use or transmit your personal
          information securely using technical and administrative security
          measures as are reasonable in the circumstances and that reduce the
          risk of accidental or unauthorised loss, misuse, access, disclosure or
          alteration.
        </Paragraph>

        <Paragraph>
          Please note that when providing personal information to {companyComp}{' '}
          via the Website, the transmission is not completely secure and will
          depend on your browser and internet settings. {companyComp} cannot
          guarantee the security of data transmitted to {companyComp} via its
          Website and any such transmission is at your own risk.
        </Paragraph>

        <Title level={3}>Cookies</Title>

        <Paragraph>
          A cookie is a piece of information that is stored on your
          computer&apos;s hard drive, or on your mobile device. {companyComp}{' '}
          offers many features that are only accessible when cookies are
          enabled. Cookies also help us to remember your membership details and
          preferences.
        </Paragraph>

        {/* <Paragraph>
          If you visit {companyComp} with your browser settings adjusted to
          accept cookies, we understand that you want to use our products and
          services. However, you can disable cookies at any time.
        </Paragraph>
        
        <Paragraph>
          Find out more about cookies and learn how to adjust your cookie
          settings.
        </Paragraph> */}

        <Title level={3}>Third Party Websites</Title>

        <Paragraph>
          {companyComp} may contain links to other websites (&quot;Third Party
          Websites&quot;) and either {companyComp} or you may post your
          information on such Third Party Websites (including, but not limited
          to, Facebook, Twitter, Instagram and YouTube). If you choose to use a
          Third Party Website, the relevant service provider (or website, as
          applicable) may use and share your data in accordance with its own
          privacy policy and your privacy settings on that Third Party Website.
          You should always review the privacy policies applicable to Third
          Party Websites to make sure you are comfortable with the ways in which
          they use information you share with them.
        </Paragraph>

        <Title level={3}>Third Party Services</Title>

        <Paragraph>
          {companyComp} may also use third party providers to provide certain
          services in connection with the {companyComp} Website, App and/or
          services. {companyComp} may disclose personally identifiable
          information to these third party service providers for the sole
          purpose of the provision of services to {companyComp} or to you, or in
          connection with your membership of {companyComp}.
        </Paragraph>

        <Paragraph>
          A list of the categories of third party service providers{' '}
          {companyComp} uses to provide services in connection with the{' '}
          {companyComp} Website, App and/or services is provided below:
        </Paragraph>

        <ul>
          <li>
            web hosting and content delivery services (such as Rackspace,
            Microsoft Azure, Cloudflare, Fastly)
          </li>
          <li>
            direct marketing services (such as Mailchimp, HubSpot, SendGrid,
            Returnpath)
          </li>
          <li>
            marketing and remarketing services (such as Google Adwords,
            Facebook)
          </li>
          <li>analytics and reporting services (such as Google Analytics)</li>
          <li>site security and monitoring services (such as Raygun)</li>
          <li>site development and maintenance</li>
          <li>
            payment services and billing agents (such as Worldpay, PayPal,
            DataCash, Spreedly, Apple, Windcave)
          </li>
          <li>mobile app services (such as Apple)</li>
          <li>social Media services (such as Instagram, Facebook, Twitter)</li>
          <li>
            collaboration and documentation services (such as Slack, Evernote,
            Google Docs, Trello, Atlassian)
          </li>
          <li>
            Malaysia advertiser and marketing and support services (such as
            Casting Days)
          </li>
          <li>Customer Relationship Management services (such as Pipedrive)</li>
          <li>
            {companyComp} will only use trusted third party service providers
            for the sole purpose of the provision of the {companyComp} Website,
            App and services or in connection with your membership.
          </li>
        </ul>

        <Title level={3}>Remarketing</Title>

        <Paragraph>
          {companyComp} also uses third party remarketing cookies, in
          particular, Google AdWords. These remarketing services may place
          cookies on web browsers in order to serve ads based on past visits to
          our Website. This means you may see ads about our service across the
          Internet, specifically on the Google Content Network (GCN). This
          allows us to make special offers and market our service to those who
          are interested in our service, and only display ads that are relevant
          to you.
        </Paragraph>

        <Paragraph>
          We respect your privacy and do not collect or store any personally
          identifiable information through Google AdWords or any other third
          party remarketing system.
        </Paragraph>

        <Paragraph>
          If you do not wish to see ads from {companyComp} you can opt out in
          several ways:
        </Paragraph>

        <ol type='1'>
          <li>
            Opt out of Google&apos;s use of cookies by visiting Google&apos;s
            Ads Settings.
          </li>
          <li>
            Opt out of a third-party vendor&apos;s use of cookies by visiting
            the Network Advertising Initiative opt-out page.
          </li>
        </ol>

        <Title level={3}>Spam and your email address</Title>

        <Paragraph>
          Your email address or Facebook account is used to identify your
          membership, and to sign in to the site.
        </Paragraph>

        <Paragraph>
          After registering with {companyComp} you will receive regular casting
          call update emails, containing casting calls and auditions matching
          your profile. You may also receive our email newsletters, account
          updates and information about {companyComp} features, and messages
          from other {companyComp} members.
        </Paragraph>

        <Paragraph>
          You can easily unsubscribe from most of our emails, either by using
          the &apos;unsubscribe&apos; link provided in the email, changing your
          email settings on your profile or by contacting us using the
          information provided below. Please note that you won&apos;t be able to
          unsubscribe from some emails relating to important activity on your
          account (e.g. payment receipts, message notifications)
        </Paragraph>

        <Title level={3}>Contact details</Title>

        <Paragraph>
          If you wish to contact us in relation to this privacy policy, our
          privacy standards, our information-handling practices, or to raise a
          data protection complaint, please contact us using the details below:
        </Paragraph>

        <Paragraph>
          Email: <Link href={`mailto://${email}`}>{email}</Link>
        </Paragraph>

        <Title level={3}>General Purpose Collection Statement</Title>

        <Paragraph>
          To assist us in providing access to and managing an audition and jobs
          platform for actors, extras, models, dancers, photographers, crew,
          stylists and musicians, and otherwise conducting our business
          functions and activities, we need to collect personal information
          about you. By providing your personal information, you agree that it
          will be collected, used and disclosed by {companyComp} and any of our
          related entities (collectively {companyComp}, we, us, or our) in
          accordance with this statement and our Privacy Policy, available at{' '}
          {privacyLink}. If you do not agree, you must not provide your personal
          information, and we may not be able to communicate with you or provide
          certain products or services to you. We may disclose your personal
          information to other parties, including third parties who provide
          products and services to us or through us in the ordinary operation,
          administration or promotion of our business and otherwise in
          accordance with our Privacy Policy. From time to time, these third
          parties may be located (and therefore your personal information may be
          disclosed) overseas, including the EU, UK, and USA. We may use and
          disclose your personal information for direct marketing purposes,
          unless you opt out (which you can do at any time in accordance with
          our Privacy Policy or by writing to {companyComp}, {emailLink}
          ). Our Privacy Policy contains information about how you may access
          and seek correction of your personal information, how you may complain
          about a breach of your privacy, and how we will deal with that
          complaint.
        </Paragraph>

        <Paragraph>
          By default, your profile is set to public, which means that all
          personal information that you provide to {companyComp} for inclusion
          in your personal profile (including all photos and film reels) will be
          collected and stored on our website to enable visitors to the Website
          and advertisers to view your profile, and your profile will also be
          accessible from search engines such as Google (unless you have set
          your profile to private).
        </Paragraph>
      </section>
      <Footer />
    </Div>
  );
};

export default PrivacyPage;
