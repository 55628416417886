import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Typography } from 'antd';
import Menu from '../../component/Menu/Menu';
import Footer from '../../component/Footer/Footer';
import { Div } from '../../framework';
import { setFlyoutOpen, setMenuMode } from '../../store/app.slice';
import * as analyticService from '../../service/analytic.service';

import './Faq.scss';

const { Title, Paragraph, Text } = Typography;

const FaqPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    analyticService.trackPage('faq');
  }, []);

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  return (
    <Div className='page-faq'>
      <Menu />
      <Helmet>
        <title>FAQ - HeyCast.Me</title>
        <meta
          name='description'
          content='The right role. The right talent. The right platform.'
        />
      </Helmet>
      <section className='statement'>
        <Title level={2}>Frequently Asked Questions</Title>
        <Title level={3}>The Basics</Title>

        <Title level={4}>What is HeyCast.Me?</Title>
        <Paragraph>
          We are a job platform that specializing in Entertainment industry. You
          may hirer Artists, Acting talents, Influencers and Models on
          heycast.me
        </Paragraph>

        <Title level={4}>What HeyCast.Me is not?</Title>
        <Paragraph>We are NOT a talent agency.</Paragraph>

        <Title level={4}>Who is HeyCast.Me for?</Title>
        <Paragraph>
          If you are acting talents, presenters and models or casting
          professional/directors, filmmakers, Producers and creative agencies,
          this is the right platform for you.
        </Paragraph>

        <Title level={4}>Who is a talent?</Title>
        <Paragraph>
          Artists, Acting talents, Models and Influencers are referred to as
          talent.
        </Paragraph>

        <Title level={4}>Who is a hirer?</Title>
        <Paragraph>
          Casting professionals, casting directors, filmmakers, producers,
          creative agencies and brand owners are referred to as hirer.
        </Paragraph>

        <Title level={4}>What do we do?</Title>
        <Paragraph>
          We are a platform that connects talents and hirers in the industry.
        </Paragraph>

        <Title level={4}>Why HeyCast.Me?</Title>
        <Paragraph>
          A long awaited high tech-savvy solution for both hirers and talents.
          We bring transparency to the hiring process through digitalization,
          innovation and simplicity into the talent searching process.
        </Paragraph>

        <Title level={4}>Why should talent use HeyCast.Me?</Title>
        <ul>
          <li>Create a digital talent profile with a unique shareable link.</li>
          <li>
            No need to search and filter suitable jobs actively, the platform
            would automatically notify the talent when there&apos;s a matched
            job.
          </li>
          <li>
            Access to all acting and modelling jobs across the industry right at
            your fingertip.
          </li>
          <li>Directly apply jobs from productions houses and filmmakers.</li>
        </ul>

        <Title level={4}>What should hirer use HeyCast.Me?</Title>
        <ul>
          <li>Post shooting jobs on the platform.</li>
          <li>Access to talent&apos;s digital profile.</li>
          <li>Simplify job applications filtering and management.</li>
        </ul>

        <Title level={4}>What are the changes HeyCast.Me brings?</Title>
        <Paragraph strong>For hirers:</Paragraph>
        <ul>
          <li>
            No longer received unorganized and incomplete talent profiles, by
            piecemeal basis.
          </li>
          <li>Direct access to all talents in the industry.</li>
          <li>Saving time and efforts in searching for talents.</li>
          <li>Total control over the talent recruitment process.</li>
        </ul>

        <Paragraph strong>For talents:</Paragraph>
        <ul>
          <li>No longer being undercut by intermediaries.</li>
          <li>No more unfair and hefty commission taken by intermediaries</li>
          <li>
            Improve transparency in the fee/payment and fairly compensated.
          </li>
          <li>Total control over the job you are to apply for and accept</li>
        </ul>

        <Paragraph strong>For the industry:</Paragraph>
        <ul>
          <li>Bring unity into a highly fragmented industry</li>
          <li>
            Bring innovation, simplicity and transparency into the industry
          </li>
          <li>Level up the playing field for all parties in the industry</li>
        </ul>

        <Title level={4}>How does HeyCast.Me works?</Title>
        <Paragraph strong>For hirers:</Paragraph>
        <Paragraph>
          First, create a job with roles on the platform. Then receiving
          submissions from talents. Lastly, select the talents whom best fit
          into the roles you offered. As easy as that.
        </Paragraph>
        <Paragraph strong>For talents:</Paragraph>
        <Paragraph>
          First, create a digital talent profile with the platform. Get notified
          when there are jobs match your talent profile. Then apply for the
          roles that best fit you and lastly sit back and wait for the status
          update on the job you applied. As easy as that.
        </Paragraph>

        <Title level={3}>Payment</Title>

        <Paragraph strong>
          Hirers: How can I pay for the talent I hirer?
        </Paragraph>
        <Paragraph>
          All the payments for the talent&apos;s work will be transferred to
          HeyCast.Me account. After deduction of tax and services, We will
          transfer the attributable sum to talent within 10 business days from
          the day full payments have been received on HeyCast.Me account.
        </Paragraph>

        <Paragraph strong>
          Talent: How do I get paid from the job I accepted?
        </Paragraph>
        <Paragraph>
          All the payments for the talent&apos;s work will be transferred to
          HeyCast.Me account. After deduction of tax and services, We will
          transfer the attributable sum to you within 10 business days from the
          day full payments have been received on HeyCast.Me account.
        </Paragraph>

        <Title level={3}>Platform Service Fee</Title>

        <Paragraph>
          By registering a talent profile on Heycast.me, as a talent, you agree
          to pay a Platform Service Fee in accordance with the fee structure
          outlined below:
        </Paragraph>

        <Paragraph>
          (a) For talent fees less than RM800, a Platform Service Fee of 10% of
          the talent fee will be applied.
        </Paragraph>

        <Paragraph>
          (b) For talent fees equal to or greater than RM800, a Platform Service
          Fee of 20% of the talent fee will be applied.
        </Paragraph>

        <Paragraph>
          This Platform Service Fee helps support and maintain the Heycast.me
          platform, ensuring continued access to a robust talent pool and
          efficient talent matching services. Please note that this fee is in
          addition to the agreed-upon talent fees and will be automatically
          deducted upon confirmation of a job.
        </Paragraph>
      </section>

      <Footer />
    </Div>
  );
};

export default FaqPage;
